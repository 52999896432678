
export function parseValue(value) {
  if(value == null || value === ""){
    return "Not provided"
  }
  return value
}

export function isValidDate(value) {
  let date = new Date(value)
  return date instanceof Date && !isNaN(date.valueOf())
}

export function parseDateFromSQLDate(value) {
  let date = new Date(value)
  let newDate = date.getDate() + "/" + parseInt(date.getMonth() + 1).toString() + "/" + date.getFullYear()

  return newDate
}

function urlHasProtocol(url) {
  if(url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
    return true
  } else {
    return false
  }
}

export function prependProtocol(url) {
  if(urlHasProtocol(url)){
    return url
  } else {
    return "https://".concat(url)
  }
}

export function prettifyValue(value) {
  let content = ""
  switch (value) {
    case "Yes":
      content = <span className='text-success fw-bold'><i className='bi-check-circle text-success fw-bold'></i></span>
      break
    case "No":
      content = <span className='text-muted fw-bold'><i className='bi bi-x'></i></span>
      break
    case "":
      content = <span><i className='bi bi-question-lg'></i></span>
      break
    default:
      content = <span><i className='bi bi-question-lg'></i></span>
      break;
  }

  return content
}

export function prettifyLabel(key, value, t) {
  let content = ""

  if(key === "shelter") {
    switch (value) {
      case "Yes":
        content = <span className='text-success fw-bold'>{t('shelter')}</span>
        break
      case "No":
        content = <span className='text-muted'>{t('shelter')}</span>
        break
      case "":
        content = <span>{t('shelter')}</span>
        break
      default:
        content = <span>{t('shelter')}</span>
        break;
    }
  }

  if(key === "health") {
    switch (value) {
      case "Yes":
        content = <span className='text-success fw-bold'>{t('health')}</span>
        break
      case "No":
        content = <span className='text-muted'>{t('health')}</span>
        break
      case "":
        content = <span>{t('health')}</span>
        break
      default:
        content = <span>{t('health')}</span>
        break;
    }
  }

  if(key === "fast_track") {
    switch (value) {
      case "Yes":
        content = <span className='text-success fw-bold'>{t('fast_track')}</span>
        break
      case "No":
        content = <span className='text-muted'>{t('fast_track')}</span>
        break
      case "":
        content = <span>{t('fast_track')}</span>
        break
      default:
        content = <span>{t('fast_track')}</span>
        break;
    }
  }

  if(key === "scholarship") {
    switch (value) {
      case "Yes":
        content = <span className='text-success fw-bold'>{t('scholarship')}</span>
        break
      case "No":
        content = <span className='text-muted'>{t('scholarship')}</span>
        break
      case "":
        content = <span>{t('scholarship')}</span>
        break
      default:
        content = <span>{t('scholarship')}</span>
        break;
    }
  }

  return content
}