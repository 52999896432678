import React from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Multiselect } from 'multiselect-react-dropdown'

const Filters = React.forwardRef((props, ref) => {

  const { t } = useTranslation()
  const options = [
    {name: t('shelter'), id: "shelter"}, 
    {name: t('health'), id: "health"}, 
    {name: t('fast_track'), id: "fast_track"}, 
    {name: t('scholarship'), id: "scholarship"}]

  const changeInput = (value) => {
    props.changeValue(value, false)
  }

  const handleCheckbox = (val) => {
    props.changeValue(val, true)
  }

  return (
    <Container className="mt-5">
      <Row className="mt-4">
        <Col xs={11} lg={9} className="m-auto">
          <p className="text-center lead text-light">
            {t('filters.label')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={9} className="m-auto">
          <Row className="mx-1">
            <Col sm={12} md={6} lg={7} className="m-auto">
              <Form.Control
                type="text"
                placeholder={t('placeholder.label')}
                onChange={(e) => changeInput(e.target.value)}
              />
            </Col>
            <Col sm={12} md={6} lg={5} className="m-auto">
              <Multiselect
                options={options}
                displayValue="name"
                placeholder={t('filtersType.label')}
                showCheckbox={true}
                closeOnSelect={true}
                ref={ref}
                avoidHighlightFirstOption={true}
                onSelect={handleCheckbox}
                onRemove={handleCheckbox}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
})

export default Filters
