import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Institution from './Institution'

function List(props) {

  const { t } = useTranslation()

  if(props.isResponseEmpty) {
    return (
      <Container className="my-5">
        <Row className="m-auto">
          <p className="text-light text-center fst-italic">{t('no_data')}</p>
        </Row>
      </Container>
    )
  }

  if((props.heiList).length === 0) {
    return (
      <Container className="my-5">
        <Row className="m-auto">
          <p className="text-light text-center fst-italic">{t('no_result')}</p>
        </Row>
      </Container>
    )
  } else {
    console.log("Total results: ", (props.heiList).length)
    return (
      <Container className="my-5">
        <Row className="m-auto">
          {props.heiList.map((hei, index) => {
            return (
              <Institution key={index} hei={hei} />
            )
          })}
        </Row>
      </Container>
    )
  }

  
}

export default List
