import { useEffect, useState } from 'react'
import { Container, Navbar, Nav, Modal, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'

function Header(props) {

  const [show, setShow] = useState(false)
  const [showInstitutions, setShowInstitutions] = useState(false)
  const [radioValue, setRadioValue] = useState('en')

  const handleClose = () => setShow(false)
  const handleCloseInstitutions = () => setShowInstitutions(false)
  const handleShow = () => setShow(true)
  const handleShowInstitutions = () => setShowInstitutions(true)

  const { t, i18n } = useTranslation()

  const handleChange = (val) => {
    i18n.changeLanguage(val)
    setRadioValue(val)
    props.resetMultiselect()
  }

  useEffect(() => {
    setRadioValue(i18n.language)
  }, [setRadioValue, i18n.language])

  return (
    <>
      <Navbar bg="" variant="" expand="md" className="text-light">
        <Container>
          <Navbar.Brand className="text-light">{t('title')}</Navbar.Brand>
          <Navbar.Toggle className="text-light">
            <i className="bi bi-list"></i>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-content-end">
            <Nav className="justify-content-end">
              <Nav.Link className="link-light" eventKey="link-institutions" onClick={handleShowInstitutions}>For institutions</Nav.Link>
              <Nav.Link className="link-light" eventKey="link-about" onClick={handleShow}>{t('about.link')}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <ToggleButtonGroup type="radio" name="radio" value={radioValue} onChange={handleChange} className="ms-3">
            <ToggleButton id="tbg-btn-1" value={"en"}>
              EN
            </ToggleButton>
            <ToggleButton id="tbg-btn-2" value={"ua"}>
              УКР
            </ToggleButton>
          </ToggleButtonGroup>
        </Container>
      </Navbar>

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>{t('about.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans>
            {t('about.label')}
          </Trans>
        </Modal.Body>
      </Modal>

      <Modal show={showInstitutions} onHide={handleCloseInstitutions} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Instructions for institutions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The information contained in this website is provided by the Higher Education Institutions themselves through <a target="_blank" rel="noreferrer" href="https://www.erasmus-dashboard.eu/intro">the Erasmus+ Dashboard</a> which can be used free of charge by Universities from the Erasmus+ programme countries and Switzerland.</p>
          <p>Detailed instructions can be found <a target="_blank" rel="noreferrer" href="https://wiki.uni-foundation.eu/display/DASH/War+in+Ukraine+-+Student+Support+Centre">here</a>.</p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Header