import React from 'react'
import Homepage from './components/Homepage'

function App() {

  return (
    <Homepage />
  )
}

export default App
