import { useEffect, useState, useRef } from 'react'
import Header from './Header'
import Footer from './Footer'
import List from './List'
import Filters from './Filters'
import axios from 'axios'
import { Oval } from 'react-loader-spinner'

import '../App.css'

function Homepage() {

  const multiselectRef = useRef(null)
  const [heiList, setHeiList] = useState([])
  const [initialHeiList, setInitialHeiList] = useState([])
  const [inputText, setInputText] = useState("")
  const [checkBoxes, setCheckBoxes] = useState([])
  const [isResponseEmpty, setIsResponseEmpty] = useState(false)
  const [loading, setLoading] = useState(false)

  const filterByText = (array, string) => {
    // Filter by name or city or country
    const resultsFromText = array.filter(hei => {
      const name = (hei.name.toLowerCase().search(string.toLowerCase()) !== -1)
      const city = (hei.city.toLowerCase().search(string.toLowerCase()) !== -1)
      const country = (hei.country.toLowerCase().search(string.toLowerCase()) !== -1)
      return name || city || country
    })

    return resultsFromText
  }

  const filterByCheckboxes = (array, checkboxes) => {
    let arrayFromCheckboxes = []
    // Loop through list of HEIs
    array.forEach(hei => {
      // Loop through list of checked filters
      let counter = 0
      checkboxes.forEach((element) => {
        for (const [key, value] of Object.entries(hei)) {
          if(key === element.id && value === "Yes" && !arrayFromCheckboxes.includes(hei)) {
            counter++
          }
        }
      })
      if(counter === checkboxes.length)
        arrayFromCheckboxes.push(hei)
    })

    return arrayFromCheckboxes
  }

  const filterResults = (changedValue, checkBox) => {
    
    if(!checkBox) {
      // When the input text is typed

      // Escape backslash
      let string = changedValue.replace(/\\/g, "\\\\")
      
      if(string.trim() !== ""){
        // Input text not empty

        if(checkBoxes.length > 0) {
          // Checkboxes not empty

          let arrayFromCheckboxes = filterByCheckboxes(initialHeiList, checkBoxes)
          let resultsFromText = filterByText(arrayFromCheckboxes, string)

          setHeiList(resultsFromText)

        } else {
          // Checkboxes empty

          let resultsFromText = filterByText(initialHeiList, string)
          
          setHeiList(resultsFromText)
        }
        setInputText(string)
      } else {
        // Input text empty
        
        if(checkBoxes.length > 0) {
          // Checkboxes not empty
          
          let arrayFromCheckboxes = filterByCheckboxes(initialHeiList, checkBoxes)
          setHeiList(arrayFromCheckboxes)

        } else {
          // Checkboxes empty

          setHeiList(initialHeiList)
        }
        setInputText("")
      }
    } else {
      // When checkboxes select is typed

      setCheckBoxes(changedValue)

      if(changedValue.length > 0) {
        // Checkboxes not empty

        if(inputText.trim() !== "") {
          // Input text not empty

          let resultsFromText = filterByText(initialHeiList, inputText)
          let arrayFromCheckboxes = filterByCheckboxes(resultsFromText, changedValue)

          setHeiList(arrayFromCheckboxes)
        
        } else {
          // Input text empty

          let arrayFromCheckboxes = filterByCheckboxes(initialHeiList, changedValue)
          setHeiList(arrayFromCheckboxes)
        }
      } else {
        // Checkboxes empty

        if(inputText.trim() !== "") {
          // Input text not empty
          
          let resultsFromText = filterByText(initialHeiList, inputText)
          setHeiList(resultsFromText)
        
        } else {
          // Input text empty

          setHeiList(initialHeiList)
        }
        
        setCheckBoxes([])
      }
    }
  }

  const loadData = async () => {
    setLoading(true)
    axios.get(process.env.REACT_APP_API_URL)
    .then((res) => {
      setHeiList(res.data)
      setInitialHeiList(res.data)
      setLoading(false)
      if((res.data).length === 0) {
        setIsResponseEmpty(true)
      }
    })
    .catch((error) => {
      setLoading(false)
    })
  }

  const resetMultiselect = () => {
    multiselectRef.current.resetSelectedValues()
    setHeiList(initialHeiList)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      <Header resetMultiselect={resetMultiselect} />
      <main className="flex-grow-1">
        <Filters changeValue={filterResults} ref={multiselectRef} />
        {loading 
          ? (<div className="loading-spinner position-absolute top-50 start-50 translate-middle">
              <Oval
                ariaLabel="loading-indicator"
                height={80}
                width={80}
                strokeWidth={8}
                strokeWidthSecondary={2}
                color="#0d6efd"
                secondaryColor="transparent" 
              />
            </div>)
          : (<List heiList={heiList} isResponseEmpty={isResponseEmpty} />)
        }
      </main>
      <Footer />
    </>
  )
}

export default Homepage
