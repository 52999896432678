import { Row, Col, Card } from 'react-bootstrap'
import { parseDateFromSQLDate, prettifyValue, prettifyLabel, isValidDate } from '../utils/Utils.js'
import { useTranslation } from 'react-i18next'

function Institution(props) {

  const { t } = useTranslation()

  return (
    <Col xs={12} md={6} xl={4} className="mt-5">
      <Card className="h-100">
        <Card.Body className="d-flex flex-column justify-content-between">
          <div className="card-top">
            <Card.Subtitle className="my-2 text-secondary fw-normal fs-6">
              <i className="bi bi-geo-alt"></i> {props.hei.country}, {props.hei.city}
            </Card.Subtitle>
            <Card.Title>{props.hei.name}</Card.Title>
          </div>
          <div className="card-middle mt-3">
            <Row>
              <Col xs={1}>
                <Card.Text>
                  {prettifyValue(props.hei.shelter)}
                </Card.Text>
              </Col>
              <Col xs={11}>
                <Card.Text className="lh-sm">
                  {prettifyLabel("shelter", props.hei.shelter, t)}
                </Card.Text>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={1}>
                <Card.Text>
                  {prettifyValue(props.hei.health)}
                </Card.Text>
              </Col>
              <Col xs={11}>
                <Card.Text className="lh-sm">
                  {prettifyLabel("health", props.hei.health, t)}
                </Card.Text>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={1}>
                <Card.Text>
                  {prettifyValue(props.hei.fast_track)}
                </Card.Text>
              </Col>
              <Col xs={11}>
                <Card.Text className="lh-sm">
                  {prettifyLabel("fast_track", props.hei.fast_track, t)}
                </Card.Text>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={1}>
                <Card.Text>
                  {prettifyValue(props.hei.scholarship)}
                </Card.Text>
              </Col>
              <Col xs={11}>
                <Card.Text className="lh-sm">
                  {prettifyLabel("scholarship", props.hei.scholarship, t)}
                </Card.Text>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={6} md={6}>
                <Card.Text>
                {props.hei.more_info_url 
                  ? (<a href={props.hei.more_info_url} className="link-primary text-capitalize fw-bold" target="_blank" rel="noreferrer">{t('more_info')}</a>)
                  : null
                }
                </Card.Text>
              </Col>
              <Col xs={6} md={6}>
                <Card.Text>
                {isValidDate(props.hei.last_updated) 
                  ? (<span className="fst-italic float-end"><small>{t('last_updated')}: {parseDateFromSQLDate(props.hei.last_updated)}</small></span>)
                  : null
                }
                </Card.Text>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default Institution
