import { Container, Row, Col } from 'react-bootstrap'
import coFunded from '../img/co-funded.png'

function Footer() {

  return (
    <footer className="footer py-4 mt-auto text-light">
      <Container>
        <Row>
          <Col xs={12} md={6} lg={3} className="mb-3">
            <img src={coFunded} alt="Co-Funded" className="img-fluid mt-1" />
          </Col>
          <Col xs={12} md={12} lg={6} className="order-md-last mb-3">
            <p className="mb-0">
              <small>This project has been funded with the support from the European Commission. 
              The website reflects the views only of the author, and the Commission cannot be held 
              responsible for any use which may be made of the information contained therein.</small>
            </p>
          </Col>
          <Col xs={12} md={6} lg={3} className="text-center order-lg-last mb-3">
            <p><a className="link-light fs-6" target={"blank"} href={"https://uni-foundation.eu/privacy-terms/"}>Privacy & Terms</a></p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer